import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import env from 'dotenv';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueCarousel from 'vue-carousel';
import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import router from './router';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

env.config();

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjPyv4RXwQn76O097BMq5zwirhYy5eFaw',
    libraries: 'places',
    region: 'BR',
    language: 'pt-BR',
  },
  installComponents: true,
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCarousel);
Vue.use(VueClipboard);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
