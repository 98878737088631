


















import Vue from "vue";
import { isMobile } from 'mobile-device-detect';
import ContentBox3 from "@/components/ContentBox3.vue";
import Address from "@/components/Address.vue";
import GroupList from "@/components/GroupList.vue";
import NewGroup from "@/components/NewGroup.vue";
import GoogleMaps from "@/components/GoogleMaps.vue";

export default Vue.extend({
  name: "Search",
  components: {
    ContentBox3,
    Address,
    NewGroup,
    GroupList,
    GoogleMaps,
  },
  data() {
    return {
      center: { lat: 0, lng: 0 },
      error: false,
      loading: false,
      postalCode: this.$route.query.postalcode || null,
      newGroup: false,
      coordinates: { lat: "", lng: "" },
      address: "",
      groups: {},
      height: ''
    };
  },
  created() {
    const storageCoordinates = localStorage.getItem("coordinates") || "";
    this.$data.coordinates = JSON.parse(storageCoordinates);

    const storageNewGroup = localStorage.getItem("newGroup") || "";
    const newGroup = JSON.parse(storageNewGroup);

    const storageGroups = localStorage.getItem("groups") || "";
    const groups = JSON.parse(storageGroups);

    const storageAddress = localStorage.getItem("address") || "";
    let address = JSON.parse(storageAddress);

    this.$data.address = address.street.concat(
        " - ",
        address.neighborhood.concat(
          ", ",
          address.city.concat(" - ", address.region)
        )
      );

    this.$data.newGroup = newGroup;

    if (!newGroup) {
      this.$data.groups = groups;
    }

    if(isMobile) {
      this.height = 'auto';
    }
  }
});
