





























import Vue from "vue";
import { mask } from "vue-the-mask/";

export default Vue.extend({
  name: "PostalCodeForm",
  directives: {
    mask,
  },
  data() {
    return {
      postalCode: "",
    
    };
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();
      
      this.$emit("postalcode", this.postalCode);
    },
  },

});
