<script>
import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import Header from '@/components/Header.vue';
import Footer from './components/Footer.vue';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


export default Vue.extend({
  name: 'App',
  components: {
    Header,
    Footer,
  },
});
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
}

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center; 
  width: 100%;
  background-color: #f8f8f8;
  max-width: 100%;

} 
@media (max-width:1200px) {
  .container {
    height: 350%;
  }
 }
@media (max-width:1000px) {
  .container {
    height: 350%;
  }
 }
 @media (max-width: 900px) {
   .container {
    height: 600%;
  }
 }
@media (max-width:768px) {
  .container {
    height: 350%;
  }
 }

</style>

<template>
  <div id="app">
    <Header />
    <div class="container">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
