<template>
  <span class="address">
    <img src="../assets/icons/localization.png" alt="" />
    <address>
      {{ address }}
    </address>
  </span>
</template>

<script>
export default {
  props: ["address"],
};
</script>

<style scoped>
.address {
  width: 373px;
  display: flex;
}

img {
  width: 18px;
  height: 22px;
}

address {
  width: 345px;
  margin-left: 10px;
  font-size: 1rem;
  color: #707070;
}
@media (max-width: 900px){
  address {
  width: 320px;
 }
}
@media (max-width: 768px) {
  address {
  margin-left: 8px;
  }
  .address {
  margin-left: 0; 
  }
}
</style>
