



















































// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Vue from "vue";
import { mask } from "vue-the-mask/";

export default Vue.extend({
  directives: {
    mask,
  },
  props: ["address"],
  data() {
    return {
      street: "",
      number: "",
      complement: "",
    };
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();

      let errors = [];

      if (this.number == "") {
        errors.push("O campo Número é obrigatório!");
      }
      if (this.number == "") {
        errors.push("O campo Complemento é obrigatório!");
      }

      this.$emit("message", errors);

      if (this.number) {
        const storageAddress = localStorage.getItem("address") || "";
        const address = JSON.parse(storageAddress);

        const number = this.number;
        const complement = this.complement;
        address.number = number;
        address.complement = complement;
        
        localStorage.address = JSON.stringify(address);

        return this.$router.push("/personal-data");
      }

      return false;
    },
  },
});
