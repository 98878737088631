import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import axios from 'axios';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Search from '../views/Search.vue';
import NewGroup from '../views/NewGroup.vue';
import JoinGroup from '../views/JoinGroup.vue';
import CreateTypeGroup from '../views/CreateTypeGroup.vue';
import ProductView from '../views/ProductView.vue';
import DocumentView from '../views/DocumentView.vue';
import PersonalDataView from '../views/PersonalDataView.vue';
import Congratulations from '../views/Congratulations.vue';
import Checkout from '../views/Checkout.vue';
import CustomerPanel from '../views/panel/CustomerPanel.vue';
import ContractPDF from '../views/ContractPDF.vue';


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/new-group',
    name: 'NewGroup',
    component: NewGroup,
  },
  {
    path: '/join-group',
    name: 'JoinGroup',
    component: JoinGroup,
  },
  {
    path: '/group-type',
    name: 'CreateTypeGroup',
    component: CreateTypeGroup,
  },
  {
    path: '/product',
    name: 'ProductView',
    component: ProductView,
  },
  {
    path: '/document',
    name: 'DocumentView',
    component: DocumentView,
  },
  {
    path: '/personal-data',
    name: 'PersonalDataView',
    component: PersonalDataView,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/congratulations',
    name: 'Congratulations',
    component: Congratulations,
  },
  {
    path: '/contract-pdf',
    name: 'ContractPDF',
    component: ContractPDF,
  },
 
  {
    path: '/groups/:code',
    beforeEnter: async (to, from, next) => {
      if (to.params) {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/groups/invite/${to.params.code}`)
          .then((response) => {
            let data = response.data;
            console.log(data);
            localStorage.address = JSON.stringify(data.address);
            localStorage.coordinates = JSON.stringify({
              lat: data.coordinates.latitude,
              lng: data.coordinates.longitude,
            });
            localStorage.group = JSON.stringify(data.group);
            localStorage.newGroup = !data.groupExists;
            localStorage.groups = JSON.stringify(data.groups);
            localStorage.product = JSON.stringify(data.product);
          })
          .catch((err) => {
            console.log(err);
            next('/');
          });
      }
      next('/join-group');
    }
  },
  {
    path: '/panel',
    name: 'CustomerPanel',
    component: CustomerPanel,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});



export default router;
