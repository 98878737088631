






import Vue from "vue";

export default Vue.extend({
  name: "ContentBoxNew",
  props: {
    height: {
      type: Number,
      default: 380
    }
  },
  computed: {
    contentBox() {
      return {
        height: `${this.height}px`
      }
    }
  }
});
