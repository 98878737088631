<template>
  <ContentBoxCongratulations :height="this.height">
    <div class="congratulations">
      <div class="congratulations-header">
        <h1>Parabéns!</h1>
        <h6 style="text-align: center;"> Copie o link e repasse para seus amigos e vizinhos de sua localidade!<br>Abra a “Área do Cliente” e acompanhe o andamento de sua rede Khronos Ao Vivo!</h6>
      </div>
      <div class="congratulations-icon">
        <img src="../assets/checked.png" alt="" />
      </div>
      <div class="congratulations-group">
        <h5>{{ groupName }}</h5>
      </div>
      <div class="congratulations-group-link">
        <input
          type="text"
          class="group-link"
          v-model="groupUrl"
        />
        <button
          class="button-link"
          type="button"
          v-clipboard:copy="groupUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          Copiar
        </button>
      </div>
      <div class="congratulations-group-share">
        <span>
          <a href="https://www.instagram.com/grupokhronos/" target="_blank"
            ><img src="../assets/instagram.png" alt=""
          /></a>
          <a href="https://www.facebook.com/GrupoKhronos" target="_blank"
            ><img src="../assets/facebook.png" alt=""
          /></a>
          <a
            href="https://api.whatsapp.com/send?1=pt_BR&amp;phone=5548991085408"
            target="_blank"
            ><img src="../assets/whatsapp.png" alt=""
          /></a>
          <img src="../assets/email.png" alt="" />
        </span>
      </div>
    </div>
  </ContentBoxCongratulations>
</template>

<script lang=~ts>
import Vue from "vue";
import ContentBoxCongratulations from "@/components/ContentBoxCongratulations.vue";

export default Vue.extend({
  components: {
    ContentBoxCongratulations,
  },
  data() {
    return {
      createdOrder: {},
      groupName: "",
      groupCode: "",
      groupUrl: "",
      baseUrl: window.location.origin,
      height: "",
    };
  },
  created() {
    const storageCreatedOrder = localStorage.getItem("createdOrder") || "";
    this.createdOrder = JSON.parse(storageCreatedOrder);

    this.groupName = this.createdOrder.group_name;
    this.groupCode = this.createdOrder.group_code;
    this.groupUrl = `${this.baseUrl}/groups/${this.groupCode}`;

    if (this.$isMobile()) {
      this.height = 'auto';
    } 
  },
  methods: {
    onCopy(url) {
      console.log('Url has been copied to clipboard.');
    },
    onError() {
      console.log('Failed to copy the text to the clipboard');
    } 
  },  
});
</script>

<style scoped>
.congratulations {
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.congratulations-group-link {
  width: 490px;
  height: 3.57em;
  box-shadow: 0px 3px 6px #c1b8b829;
  border: 2px solid #bebbbb;
  border-radius: 24px;
}

.congratulations-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congratulations-group-share,
.congratulations-group,
.congratulations-icon,
.congratulations-group-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.congratulations-group-link {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.group-link {
  width: 400px;
  color: #333333;
  margin-left: 10px;
}

.button-link {
  background-color: #e30417;
  border-radius: 20px;
  width: 80px;
  height: 40px;

  font-family: Roboto;
  font-size: 0.929rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.congratulations-group-share img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

@media (max-width:768px) {
  .congratulations {
    width: 310px;    
  }


  .congratulations-group-link {
    padding-right: 0;
    margin-left: 17px;
  }

  .group-link {
    margin-left: 15px;
  }

  .button-link {
    height: 40px;
  }
}

@media (max-width:900px) {

  .congratulations-group-link {
    width: 350px;
    height: 40px;
    padding-right: 0;
  }

  .group-link {
    width: 350px;
    margin-left: 15px;
  }

  .button-link {
    width: 100px;
    height: 40px;
  }
}
</style>
