





































import Vue from "vue";

export default Vue.extend({
  name: "ProductSlider",
  props: ["products"],
  data() {
    const productList = this.products;
    const activeIndex = 0;
    return {
      description: null,
      active: false,
      activeIndex,
      productList,
      currentIndex: 0,
      product: productList[activeIndex]
    };
  },
  computed: {},
  created() {
    this.$emit("productSelected", this.product); // send description
  },
  methods: {
    setActive(index: any){
      this.activeIndex = index;
      this.product = this.productList[index];
      this.$emit("productSelected", this.product);
    },
    currencyConvert(value = 0) {
      return value.toFixed(2);
    },
  
  },

  
});

