<template>
  <div class="google-maps">
    <GmapMap
      :center="{
        lat: latitude(),
        lng: longitude(),
      }"
      :zoom="15"
      :clickable="false"
      :draggable="false"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
      :style="size"
    >
      <GmapMarker
        :position="{
          lat: latitude(),
          lng: longitude(),
        }"
        :clickable="false"
        :draggable="false"
      />
    </GmapMap>
  </div>
</template>

<script>
import isMobile from "mobile-device-detect";

export default {
  props: {
    coordinates: {},
    height: {
      type: Number,
      default: 332
    },
    width: {
      type: Number,
      default: 421
    }
  },
  computed: {
    size() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`
      }
    }
  },
  methods: {
    latitude() {
      return this.coordinates.lat;
    },
    longitude() {
      return this.coordinates.lng;
    },
  },
  created() {
    if(isMobile) {
      this.width = 330;
    }
  }
};
</script>

<style>
</style>
