
























































































import Vue from "vue";
import { isMobile } from "mobile-device-detect";
import ContentBox2 from "@/components/ContentBox2.vue";
import { BAlert } from "bootstrap-vue";

export default Vue.extend({
  name: "CreateTypeGroup",
  components: {
    ContentBox2,
    BAlert
  },
  data() {
    return {
      groupType: "",
      errors: [],
      dismissSecs: 10,
      dismissCountDown: 0,
      height: '',
    };
  },
  created() {
    if(isMobile) {
      this.height = 'auto';
    }
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();

      let errors = [];

      if (this.groupType == "") {
        errors.push("Escolha o tipo de grupo!");
      }

      this.$data.errors = errors;

      if (this.groupType) {

         const storageGroup = localStorage.getItem("group") || "";
        const group = JSON.parse(storageGroup);

        group.type = this.groupType;
        localStorage.group = JSON.stringify(group);

        return this.$router.push("/personal-data"); 
      }

      return true;
    },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    scroll(){
        window.scrollTo(100, 100);
    },
  },
  watch: {
    errors() {
      this.showAlert();
    },
  },
});
