<template>
  <div class="new-group">
    <div class="new-group-response">
      <h4>Nenhum grupo encontrado</h4>
      <p>
        Não encontramos nenhum grupo com este CEP {{ postalcode }}, cadastre-se e inicie agora um grupo.
      </p>
    </div>
    <div class="actions">
      <button @click="goToNewGroup" class="button-new-group">
        Criar Grupo
      </button>
      <a class="read-more" href="#">Leia as Informações</a>
    <b-modal  header-close-content="❌" hide-footer hide-backdrop header-text-variant="danger" size="lg" id="modal-1" title="">
      <iframe width="770" height="500" class="player" src="https://www.youtube.com/embed/8wqLewnIvGc"  frameborder="0"  allowfullscreen></iframe>
     </b-modal>
    </div>
     <a v-b-modal.modal-1 style="font-size: 0,8em; font-weight: bold;" >Ficou com duvidas? Clique e saiba mais</a>  
    </div>
</template>

<script>
export default {
  props: ["postalcode"],
  methods: {
    goToNewGroup() {
      return this.$router.push("/new-group");
    },
  },
};
</script>

<style scoped>
.new-group-response {
  margin-top: 95px;
}

.new-group-response > h4 {
  font-family: Roboto;
  font-size: 1.071rem;
  font-weight: bold;
  color: #333333;
}

.new-group-response > p {
  font-family: Roboto;
  font-size: 1.143rem;
  color: #707070;
}

div.actions {
  width: 100%;
  height: 40px;
  margin-top: 44px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.button-new-group {
  background-color: #891418;
  border-radius: 20px;
  width: 168px;
  height: 40px;
  font-family: Roboto;
  font-size: 0.929rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  right: 325px;
  margin: 10px;
}

.read-more {
  text-decoration: none;
  margin-left: 26px;
  font-family: Roboto;
  font-size: 0.929rem;
  font-weight: bold;
  color: #891418;
  text-transform: uppercase;
  display: none;
}

@media (max-width: 900px) {
  .new-group-response > p {
    font-size: 1.25em;
  }
    .button-new-group {
    position: absolute;
    left: 390px;
    bottom: 40px !important;
  }
  .player{
    width: 470px !important;
    height: 400px;
  }
}
@media (max-width:1200px){
 .player{
    width: 770px;
    height: 400px;
  }
   .button-new-group {
    position: absolute;
    left: 390px;
    bottom: 65px;
  }
}
@media (max-width:768px) {
  .new-group-response {
    margin-top: 30px;
  }
   .player{
   width: 375px !important;
   height: 350px;
  }
  .button-new-group {
   position: absolute;
   margin-right: 80px;
   width: 170px;
   bottom: 30px;
   left: 70px !important;
  }
  
}
</style>
