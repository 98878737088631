















































































import Vue from "vue";
import axios from 'axios';

export default Vue.extend({
  name: "GroupList",
  props: ["groups"],
  components: {
  
  },

  
   
  data() {
    const groupList = this.groups;
    const activeIndex = 0;
    return {
      activeIndex,
      active: false,
      error: false,
      number: '',
      groupList,
      group: groupList[activeIndex],
       
    }
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();
      console.log(this.group);
      localStorage.group = JSON.stringify(this.group);
      this.getProduct();

      return this.$router.push("join-group");
    },
    setActive(index: any){
      this.activeIndex = index;
      this.group = this.groupList[index];
    },
    async getProduct() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/products/${this.group.product_id}`, {
        })
        .then((response) => {
          let product = {};
          let data = response.data;
          console.log(data);
          product = {
            title: data.name,
            plan: data.plan,
            code: data.code,
            accessQty: data.access_qty,
            price: Number(data.price),
            description: data.description
          };
          console.log(product);
          localStorage.product = JSON.stringify(product);
        })
        .catch(() => {
          this.error = true;
        });
    },
    createGroup(e: Event) {
      e.preventDefault();
      return this.$router.push('/new-group');
    }
  },
  
});
