<template>
  <footer>
    <div class="content">
     
      <div class="footer-address">
        <address>
          Rua Paulino Pedro Hermes, 3000 . Jd. Floresta . São José - SC . CEP:
          88110-693 . Fone: (48) 3381.9999
        </address>  
      </div>
      <div class="khronos-logo">
        <img src="@/assets/khronos-footer.png"/>
      </div>  
      <div class="copyright">
        <span>&copy; 2016 - 2022 Grupo Khronos</span>
      </div>
      
    </div>
    
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
  
  .khronos-logo img {
    height: 20px;  
    position: relative;
    margin: 0 auto;  
  }
  
  footer {
    width: 100%;
    background-color: #f8f8f8;
  }

  footer .content {
    max-width: 970px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .footer-address {
    color: #737373;
    font-size: 0.969rem;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 5px;
  }

  .copyright {
    color: #737373;
    font-size: 0.75rem;
    font-weight: 300; 
  }

   @media (max-width: 1200px) {
   .khronos-logo img {
     position: relative;
   }
  }

   @media (max-width: 900px) {
    .footer-address {
     width: 320px;
     text-align: center;
      }
     .khronos-logo img {
     position: relative;
    }
    }
  @media (max-width: 768px) {
    .footer-address {
      width: 320px;
      text-align: center;
    }
     .khronos-logo img {
      position: relative;
   }
  }

</style>
