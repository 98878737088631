


























































































































import Vue from "vue";
import { BAlert } from "bootstrap-vue";
import axios from "axios";

export default Vue.extend({
  components: {
    BAlert,
  },
  data() {
    return {
      fields: ["name", "participants", "status"],
      items: [],
      groupName: "",
      groupCount: 4,
      groupStatus: "",
      name: "",
      celphone: "",
      email: "",
      subject: "none",
      message: "",
      error: false,
      errors: [],
      dismissSecs: 10,
      dismissCountDown: 0,
      show: false,
      validated: false,
      variant: "danger",
      groupCode: "",
      groupUrl: "",
      baseUrl: window.location.origin,
    };
  },
  async created() {
    const storageUser = localStorage.getItem("user") || "";
    const user = JSON.parse(storageUser);

    const requestParam = {
      groupId: user.groupId,
    };

    await axios
      .post(`${process.env.VUE_APP_API_URL}/group/customers`, requestParam)
      .then((res) => {
        const group = res.data.group;
        const customers = res.data.customers;

        this.groupName = group.name;
        this.groupCount = group.customersCount;
        this.groupStatus = group.status;
        this.groupCode = group.code;

        this.items = customers;

        this.groupUrl = `${this.baseUrl}/groups/${this.groupCode}`;
      })
      .catch((err) => {
        this.error = true;
        let errorMessage = [err.response.data];

        this.$data.errors = errorMessage;
        this.show = false;
      });
    return false;
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();

      let errors = [];

      if (this.name == "") {
        errors.push("O campo Nome é obrigatório!");
      }

      if (this.celphone == "") {
        errors.push("O campo Celular é obrigatório!");
      }

      if (this.email == "") {
        errors.push("O campo E-mail é obrigatório!");
      }
      if (this.subject == "none") {
        errors.push("Selecione um assunto!");
      }

      if (this.message == "") {
        errors.push("O campo Mensagem é obrigatório!");
      }

      if (errors.length) {
        this.variant = "danger";
      }

      this.$data.errors = errors;

      if (!errors.length) {
        this.$data.validated = true;
      }

      if (this.$data.validated) {
        // Enviar os valores para o Backend
        const emailContact = this.createRequest();

        this.createEmailContact(emailContact);
      }

      return false;
    },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    createRequest() {
      const mailMessage = {
        group: this.groupName,
        name: this.name,
        celphone: this.celphone,
        email: this.email,
        subject: this.subject,
        message: this.message,
      };

      return mailMessage;
    },
    async createEmailContact(value: any) {
      this.show = true;
      const emailcontact = JSON.stringify(value);
      await axios
        .post(`${process.env.VUE_APP_API_URL}/message`, emailcontact, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let data = response.data;
          this.show = false;
          this.error = false;
          this.clearFields();
          this.variant = "success";
          this.$data.errors = response.data;
        })
        .catch((err) => {
          this.error = true;
          this.show = false;
          let errorMessage = [err.response.data];
          this.variant = "danger";
          this.$data.errors = errorMessage;
        });
    },
    clearFields() {
      this.name = "";
      this.celphone = "";
      this.email = "";
      this.subject = "none";
      this.message = "";
      this.validated = false;
      this.show = false;
    },
    onCopy(url: any) {
      console.log('Url has been copied to clipboard.');
    },
    onError() {
      console.log('Failed to copy the text to the clipboard');
    }
  },
  watch: {
    errors() {
      this.showAlert();
    },
  },
});
