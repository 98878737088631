
























import Vue from "vue";
import { isMobile } from 'mobile-device-detect';
import ContentBoxNew from "@/components/ContentBoxNew.vue";
import GoogleMaps from "@/components/GoogleMaps.vue";
import CreateGroup from "@/components/CreateGroup.vue";
import { BAlert } from "bootstrap-vue";

export default Vue.extend({
  name: "NewGroup",
  components: {
    ContentBoxNew,
    GoogleMaps,
    CreateGroup,
    BAlert
  },
  data() {
    return {
      coordinates: { lat: 0, lng: 0 },
      address: "",
      error: false,
      errors: [],
      dismissSecs: 10,
      dismissCountDown: 0,
      height: ''
    };
  },
  created() {
    const storageCoordinates = localStorage.getItem("coordinates") || "";
    const storageAddress = localStorage.getItem("address") || "";

    this.$data.coordinates = JSON.parse(storageCoordinates);
    this.$data.address = JSON.parse(storageAddress);

    if(isMobile) {
      this.height = 'auto';
    }
  },
  methods: {
    getErrors(errors: any) {
      this.errors = errors;
    },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  watch: {
    errors() {
      this.showAlert();
    }
  }
});
