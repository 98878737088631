
import Vue from 'vue';


export default Vue.extend({
  name: 'Navbar',
  data() {
    return {
      showNavbar: false,
    };
  },
  
  methods: {
    handleMenuClick() {
      this.showNavbar = !this.showNavbar;
    },
  },
});
