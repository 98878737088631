






































































import Vue from "vue";
import { mask } from "vue-the-mask/";

export default Vue.extend({
  directives: {
    mask,
  },
  props: ["address"],
  data() {
    return {
      street: "",
      number: "",
      groupName: "",
      complement: "",
    };
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();
      let errors = [];

      if(this.number == '') {
        errors.push('O campo Número é obrigatório!');
      }

      if(this.groupName == '') {
        errors.push('O campo Nome do Grupo é obrigatório!');
      }

      this.$emit('message', errors);

      if (this.number && this.groupName) {
        const storageAddress = localStorage.getItem("address") || "";

        const address = JSON.parse(storageAddress);
        const number = this.number;
        const complement = this.complement;

        address.number = number;
        address.complement = complement;
        localStorage.address = JSON.stringify(address);

        const group = {
          name: this.groupName,
          postcode: address.postcode,
          street: address.street,
          number: address.number,
          complement: address.complement,
          neighborhood: address.neighborhood,
          city: address.city,
          region: address.region,
          latitude: address.latitude,
          longitude: address.longitude,
        }
        console.log(group);
        console.log(address);

        localStorage.group = JSON.stringify(group);
        localStorage.newGroup = JSON.stringify(true);

        return this.$router.push("/product");
      }

      return false;
    },
  },
});
