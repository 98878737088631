

















































































































import Vue from "vue";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import axios from "axios";
import { BAlert, BOverlay } from "bootstrap-vue";
import { isMobile } from 'mobile-device-detect';
import ContentBox2 from "../components/ContentBox2.vue";



export default Vue.extend({
  name: "Checkout",
  components: {
    VuePdfApp,
    ContentBox2,
    BAlert,
    BOverlay,
    
  },
  data() {
    return {
      customer: {},
      address: {},
      group: {},
      product: {},
      error: false,
      errors: [],
      validated: false,
      paymentMethod: "billet",
      adhesionContract: false,
      proposal: false,
      groupType: "",
      productTitle: "",
      productPlan: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      show: false,
      height: '',
      pdf: '',
      color: 'gray',
    };
  },
  created() {
    const storageCustomer = localStorage.getItem("customer") || "";
    const storageAddress = localStorage.getItem("address") || "";
    const storageGroup = localStorage.getItem("group") || "";
    const storageProduct = localStorage.getItem("product") || "";

    this.$data.customer = JSON.parse(storageCustomer);
    this.$data.address = JSON.parse(storageAddress);
    this.$data.group = JSON.parse(storageGroup);
    this.$data.product = JSON.parse(storageProduct);

    if (this.$data.group.type === "closed") {
      this.groupType = "Fechado";
    } else {
      this.groupType = "Aberto";
    }

    this.productTitle = this.$data.product.title;
    this.productPlan = this.$data.product.plan;

    if(isMobile) {
      this.height = 'auto';
    }
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();

      let errors = [];

      const paymentMethod = this.paymentMethod;
      const proposal = this.proposal;
      const adhesionContract = this.adhesionContract;

      if (!paymentMethod) {
        errors.push("Selecione uma forma de pagamento!");
      }

      if (!proposal) {
        errors.push("Aceite a proposta!");
      }

      if (!adhesionContract) {
        errors.push("Aceite o contrato de adesão!");
      }

      this.$data.errors = errors;

      if (!errors.length) {
        this.$data.validated = true;
      }

      if (this.$data.validated) {
        // Enviar os valores para o Backend
        const order = this.createRequest();

        this.createOrder(order);
        this.postRdStation(order);
      }

      return false;
    },
    createRequest() {
      const order = {
        order: {
          group: this.requestGroup(),
          customer: this.requestCustomer(),
          payment: this.requestPayment(),
          additionalInformations: this.requestAdditionalInformation(),
        },
       }; 
       console.log(order);
      return order;
    },
    requestGroup() {
      const storageGroup = localStorage.getItem("group") || "";
      const group = JSON.parse(storageGroup);

      const storageNewGroup = localStorage.getItem("newGroup") || "";
      const newGroup = JSON.parse(storageNewGroup);

      const storageProduct = localStorage.getItem("product") || "";
      const product = JSON.parse(storageProduct);

      return {
        newGroup,
        name: group.name,
        type: group.type,
        code: group.code || "",
        postcode: group.postcode,
        street: group.street,
        number: group.number || 0,
        complement: group.complement || '',
        neighborhood: group.neighborhood,
        city: group.city,
        region: group.region,
        latitude: group.latitude,
        longitude: group.longitude,
        product,
      };
    },
    requestCustomer() {
      const storageCustomer = localStorage.getItem("customer") || "";
      const customer = JSON.parse(storageCustomer);

      return {
        name: customer.name,
        documentCpf: customer.documentcpf,
        documentRg: customer.documentrg,
        birthday: customer.birthday,
        email: customer.email,
        celphone: customer.celphone,
        address: this.requestAddress(),
      };
    },
    requestAddress() {
      const storageAddress = localStorage.getItem("address") || "";
      const address = JSON.parse(storageAddress);

      return {
        postcode: address.postcode,
        street: address.street,
        number: address.number || 0,
        complement: address.complement || '',
        neighborhood: address.neighborhood,
        city: address.city,
        region: address.region,
        latitude: address.latitude,
        longitude: address.longitude,
      };
    },
    requestPayment() {
      return {
        paymentMethod: this.paymentMethod,
      };
    },
    requestAdditionalInformation() {
      return {
        proposal: this.requestProposal(),
        adhesionContract: this.requestAdhesionContract(),
      };
    },
    requestProposal() {
      return {
        accepted: this.proposal,
        date: Date.now(),
      };
    },
    requestAdhesionContract() {
      return {
        accepted: this.adhesionContract,
        date: Date.now(),
      };
    },
  
    async createContractPdf(){
     const value = this.createRequest();
     const order = JSON.stringify(value); 
     await axios
        .post(`${process.env.VUE_APP_API_URL}/orders/contract-pdf`, order, {
          headers: {
           responseType: "",
           "Content-Type": "application/json",
          },
        })
        .then((response) => {
          var decodedPdfContent = Buffer.from(response.data, 'base64');
          const blob = new Blob([decodedPdfContent]);
          this.pdf = response.data;
          
          
        })
        .catch((err) => {
          console.log(err)
        });
     },
    async createOrder(value: any) {
      this.show = true;
     const order = JSON.stringify(value); 
     await axios
        .post(`${process.env.VUE_APP_API_URL}/orders/create`, order, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let data = response.data;
          this.show = false;
          localStorage.removeItem("address");
          localStorage.removeItem("groups");
          localStorage.removeItem("group");
          localStorage.removeItem("customer");
          localStorage.removeItem("product");
          localStorage.removeItem("coordinates");
          localStorage.removeItem("newGroup");
          localStorage.removeItem("newGroup");
          localStorage.createdOrder = JSON.stringify(data);
          return this.$router.push("/congratulations");
        })
        .catch((err) => {
          this.error = true;
          console.log(err)
          let errorMessage = [err.response.data];

          this.$data.errors = errorMessage;
          this.show = false;

          return this.$router.push('/personal-data');
        });
    },
         async postRdStation(value: any) {
      this.show = true;
     const order = JSON.stringify(value); 
     await axios
        .post(`${process.env.VUE_APP_API_URL}/orders/postRd`, order, {
          headers: {
            "Content-Type": "application/json",
          }
        })
     },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }, 
    checkTerms() {
        if (this.proposal && this.adhesionContract){
         this.color = "#ce0a15"
        } else {
          this.color = "gray"
        }
    },
  },

   
    

  watch: {
    errors() {
      this.showAlert();
    },
  },
});
