var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"google-maps"},[_c('GmapMap',{style:(_vm.size),attrs:{"center":{
      lat: _vm.latitude(),
      lng: _vm.longitude(),
    },"zoom":15,"clickable":false,"draggable":false,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    }}},[_c('GmapMarker',{attrs:{"position":{
        lat: _vm.latitude(),
        lng: _vm.longitude(),
      },"clickable":false,"draggable":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }