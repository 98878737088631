import { render, staticRenderFns } from "./ContentBox2.vue?vue&type=template&id=7b02328a&scoped=true&"
import script from "./ContentBox2.vue?vue&type=script&lang=ts&"
export * from "./ContentBox2.vue?vue&type=script&lang=ts&"
import style0 from "./ContentBox2.vue?vue&type=style&index=0&id=7b02328a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b02328a",
  null
  
)

export default component.exports