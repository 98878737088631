














































import Vue from "vue";
import { isMobile } from 'mobile-device-detect';
import axios from "axios";
import { BAlert, BOverlay } from "bootstrap-vue";
import ContentBox2 from "../components/ContentBox2.vue";

export default Vue.extend({
  name: "DocumentView",
  components: {
    ContentBox2,
    BAlert,
    BOverlay
  },
  data() {
    return {
      error: false,
      loading: false,
      file: "",
      errors: [],
      dismissSecs: 10,
      dismissCountDown: 0,
      show: false,
      height: '',
    };
  },
  created() {
    if(isMobile) {
      this.height = 'auto';
    }
  },
  methods: {
    handleFileUpload(event: any) {
      this.file = event.target.files[0];
    },
    async handleSubmit(e: Event) {
      e.preventDefault();
      let errors = [];
      let formData = new FormData();
      formData.append("file", this.file);

      if (this.file == "") {
        errors.push("Insira um documento!");
        this.$data.errors = errors;
      }

      if (this.file) {
       
        if (this.error) {
          errors.push("");
          this.$data.errors = errors;
        } else { 
          localStorage.setItem("image", this.file)
          this.$router.push({
            path: "personal-data",
          });
        }
      }

      return false;
    },
    async getDocumentValues(value: FormData) {
      this.show = true;
      await axios
        .post(`${process.env.VUE_APP_API_URL}/document/check`, value, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data = response.data;
          localStorage.customer = JSON.stringify(data);
          console.log(JSON.stringify(data));
          this.error = false;
          this.show = false;
        })
        .catch((err) => {
          this.error = true;
          let errorMessage = [err.response.data];

          this.$data.errors = errorMessage;
          this.show = false;
        });

      return "";
    },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
  watch: {
    errors() {
      this.showAlert();
    },
  },
});
