<template>
  <div class="page-container">
    <form method="post" class="form-container" @submit="onSubmitForm">
      <h1>Entrar</h1>
      <InputAuth
        type="text"
        source="user-icon.png"
        placeholder="Nome do usuário ou endereço de e-mail"
        :onChange="onChangeEmail"
        required
      />
      <InputAuth
        type="password"
        source="lock-icon.png"
        placeholder="Senha"
        :onChange="onChangePassword"
        required
      />
      <div class="keep-me-connected">
        <Checkbox label="Mantenha me conectado" />
      </div>
      <div class="buttons-container">
        <SubmitButtonAuth label="Acesso" />
      </div>
      <div class="links-container">
        <span>
          Você precisa encontrar
          <router-link to="/recover/account" class="recover-account">
            seu nome de usuario
          </router-link>
          ou
          <router-link to="/recover/account" class="recover-account">
            senha?
          </router-link>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import InputAuth from "@/components/InputAuth.vue";
import SubmitButtonAuth from "@/components/SubmitButtonAuth.vue";
import Checkbox from "@/components/Checkbox.vue";

export default Vue.extend({
  name: "Login",
  components: {
    InputAuth,
    Checkbox,
    SubmitButtonAuth,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    onChangeEmail(event) {
      const { value } = event.target;
      this.email = value;
    },
    onChangePassword(event) {
      const { value } = event.target;
      this.password = value;
    },
    async onSubmitForm(event) {
      event.preventDefault();
      const login = {
        email: this.email,
        password: this.password,
      };

      await axios
        .post(`${process.env.VUE_APP_API_URL}/login`, login)
        .then((res) => {
          const token = res.data.token;
          const user = res.data.user;

          const storageUser = {
            name: user.name,
            email: user.email,
            groupId: user.group,
          }

          localStorage.token = JSON.stringify(token);
          localStorage.user = JSON.stringify(storageUser);

          // this.show = false;
          this.$router.push("/panel");
        })
        .catch((err) => {
          this.error = true;
          let errorMessage = [err.response.data];

          this.$data.errors = errorMessage;
          this.show = false;
        });
      return false;
    },
  },
});
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  background: $color-bg-auth;

  > .form-container {
    padding-top: 98px;
    width: 500px;

    @media (max-width:768px) {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 20px;
    }
  
    > h1 {
      font-size: 1.563rem;
      font-weight: 700;
      color: #333;
      text-align: center;
      margin-bottom: 32px;
    }

    > .input-container + .input-container {
      margin-top: 20px;
    }

    > .keep-me-connected {
      margin-top: 22px;
      margin-bottom: 11px;
      margin-left: 16px;
      display: flex;
      justify-content: space-between;
    }

    > .buttons-container {
      margin-top: 11px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }

    > .links-container {
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        text-decoration: none;
        color: $color-grey-darken-5;
        font-size: 0.875rem;
        font-weight: 300;
        margin-bottom: 9em;

        > .recover-account {
          font-weight: 600;
          text-decoration: none;
          color: $color-grey-darken-5;
        }
      }
    }
  }
}
</style>
