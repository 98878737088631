


































import Vue from "vue";
import { isMobile } from 'mobile-device-detect';
import ProductSlider from "@/components/ProductSlider.vue";
import ContentBoxProduct from "@/components/ContentBoxProduct.vue";

export default Vue.extend({
  name: "ProductView",
  components: {
    ProductSlider,
    ContentBoxProduct,  
  },
  data() {
    return {
      products: {
        0: {
          title: "Plano",
          plan: "Vizinho Colaborativo ",
          code: "000254000000221",
          accessQty: 3,
          acess: "Acessos",
          price: 39.9,
          description: " Plano Vizinho Colaborativo \n R$ 39,90 (por assinatura)/mês \n \n &#10004; Plano com 2 assinaturas ou mais \n &#10004; 3 acessos por  assinatura \n &#10004; Implantação de 1 câmera \n \n OBS: Para ativar a sua rede de segurança &nbsp; &nbsp;colaborativa é necessário ter, no mínimo, 2 &nbsp;assinaturas com 2 cpf distintos"
          
        },

        1: {
          title: "Plano",
          plan: "Família Colaborativa",
          code: "000254000000223",  
          accessQty: 6,
          acess: "Acessos",
          price: 79.90,
          description: " Plano Família Colaborativa \n R$ 79,90/mês  \n \n &#10004; Plano com 1 assinatura \n &#10004; Direito a 6 acessos por assinatura &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  \n &#10004; 1 câmera por plano" 
           
        },
         2: {
          title: "Plano",
          plan: "Leitura de Placas Colaborativo",
          code: '000254000000226',  
          accessQty: 1,
          acess: "Acesso",
          price: 449.9,
          description: " Plano Leitura de Placas Colaborativa \n R$ 449,90/mês \n  \n &#10004; Plano com 1 assinatura \n &#10004; 1 câmera \n &#10004; Gerenciamento restrito \n &#10004; Plataforma com analítico de leitura de &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; placas veiculares \n  &#10004; Ideal para condomínios, loteamentos e &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;áreas industriais"

        },
        3: { 
          title: "Plano",
          plan: "Rua Protegida",
          code: '000254000000226', 
          accessQty: 50,
          acess: "Acessos",
          price: 599.9,
          description: " Plano Rua Protegida \n R$ 599,90/mês \n \n &#10004; 1 tótem com 4 câmeras \n &#10004; 50 acessos por assinatura  \n &#10004; Ideal para condomínios, loteamentos e &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;áreas industriais "
            
        },                                  
      }, 
      product: {
        title: "",
        plan: "",
        code: "",
        accessQty: null,
        price: null,
        description: "",
      },
      errors: [],
      dismissSecs: 10,
      dismissCountDown: 0,
      height: '',
    };
  },
  created(){
    if(isMobile) {
      this.height = 'auto';
    }
  },
  methods: {
    handleSubmit(e: Event) {
      e.preventDefault();
      if (typeof this.product !== "undefined" || this.product !== null) {
        localStorage.product = JSON.stringify({
          title: this.product.title,
          plan: this.product.plan,
          code: this.product.code,
          accessQty: this.product.accessQty,
          price: this.product.price,
          description: this.product.description,
        });
      }
      return this.$router.push("group-type");
      
    },
   
  },
  watch: {
    product() {
      localStorage.product = JSON.stringify({
          title: this.product.title,
          plan: this.product.plan,
          code: this.product.code,
          accessQty: this.product.accessQty,
          price: this.product.price,
          description: this.product.description,
      });       
    }
  }
});
