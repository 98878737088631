





































































































































































import Vue from "vue";
import { mask } from "vue-the-mask/";
import { isMobile } from 'mobile-device-detect';
import axios from "axios";
import { BAlert, BOverlay } from "bootstrap-vue";
import ContentBoxPD from "../components/ContentBoxPD.vue";



export default Vue.extend({
  name: "PersonalDataView",
  components: {
    ContentBoxPD,
    BAlert,
    BOverlay,
  },
  directives: {
    mask,
  },
  data() {
    return {
      customer: {},
      address: {},
      group: {},
      error: false,
      errors: [],
      celphone: "",
      email: "",
      name: "",
      complement: "",
      birthday: "",
      documentcpf: "",
      documentrg: "",
      number: "",
      dismissSecs: 10,
      dismissCountDown: 0,
      show: false,
      disabled: true,
      height: '',
    };
  },
  created() {
    const storageAddress = localStorage.getItem("address") || "";
    this.$data.address = JSON.parse(storageAddress);  
    const storageGroup = localStorage.getItem("group") || "";
    this.$data.group = JSON.parse(storageGroup);
    const storageCustomer = localStorage.getItem("customer") || "";
    this.$data.customer = JSON.parse(storageCustomer);
    
    
    if(isMobile) {
      this.height = 'auto';
    }
  },
  methods: {
    async handleSubmit(e: Event) {
      e.preventDefault();

      let errors = [];

      if (this.celphone == "") {
        errors.push("O campo Número de Telefone é obrigatório!");
      }

      if (this.email == "") {
        errors.push("O campo E-mail é obrigatório!");
      }
      if (this.birthday == "") {
        errors.push("O campo Nascimento é obrigatório!");
      }
      if (this.documentcpf == "") {
        errors.push("O campo CPF é obrigatório!");
      }
      if (this.documentrg == "") {
        errors.push("O campo RG é obrigatório!");
      }
      if (this.name == "") {
        errors.push("O campo Nome é obrigatório!");
      }


      this.$data.errors = errors;

      if (this.celphone && this.email && this.name && this.birthday && this.documentcpf && this.documentrg) {
        this.$data.customer.celphone = this.celphone;
        this.$data.customer.email = this.email;
        this.$data.customer.documentrg = this.documentrg;
        this.$data.customer.documentcpf = this.documentcpf;
        this.$data.customer.birthday = this.birthday;
        this.$data.customer.name = this.name;

        localStorage.customer = JSON.stringify(this.$data.customer);


        if (this.error) {
         console.log(this.error)
        } else {
          this.$router.push({
            path: "/checkout",
          });
        }
      }

      return false
    },
    async validateEmail(e: Event) {
      e.preventDefault();

      let errors = [];

      if (this.email == "") {
        errors.push("O campo E-mail é obrigatório!");
      }

      this.$data.errors = errors;

      const email = {
        email: this.email
      };

      this.show = true;

      await axios
        .post(`${process.env.VUE_APP_API_URL}/user/email-check`, email, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.show = false;
          let data = response.data;
          this.$data.disabled = false;

          if(data) {
            this.$data.errors = ["E-mail já utilizado! Insira um novo e-mail."];
            this.$data.disabled = true;
          } 
        })
        .catch((err) => {
          this.error = true;
          let errorMessage = [err.response.data];

          this.$data.errors = errorMessage;
          this.$data.disabled = true;
          this.show = false;
        });

    },
    countDownChanged(dismissCountDown: any) {
      this.$data.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  },
  watch: {
    errors() {
      this.showAlert();
    },
  },
});
