<template>
  <div class="checkbox-container">
    <input type="checkbox" name="check" id="check" class="box" />
    <label for="check" class="text">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: String,
  },
};
</script>

<style scoped lang="scss">
  .checkbox-container {
    width: 100%;
    display: flex;
    align-items: center;

  > .box {
    width: 22px;
    height: 22px;
    background: white;
    appearance: none;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
  }

  > .box:checked {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .box:checked:after {
    content: "✔️";
    font-size: 12px;
    color: $color-selected;
  }

  > .text {
    margin-left: 15px;
    font-size: 13px;
    font-weight: 300;
    color: $color-grey-darken-5;
    cursor: pointer;
  }
}
</style>
