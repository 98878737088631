
<script>
 import Vue from "vue";
 import ContentBoxPDF from "../components/ContentBoxPDF.vue";
 
 export default Vue.extend({
  name: "ContractPDF",
  components: {
    ContentBoxPDF,
  },
 });
 </script>

 <template> 
  <ContentBoxPDF>
   <form class="form-pdf">
     <div class="pdf">
      <img src="../assets/step_4.png" alt="" class="img" />
      <button class="btn-next">prosseguir</button>
      <h2 class="pdf-title"> Leia seu contrato abaixo: </h2>
      <p class="description"> Aqui você pode ler o seu contrato antes de prosseguir para a ultima etapa. </p>
      <embed class="frame-pdf" src="12020014912.pdf" type="application/pdf" width="850px" height="250px" />
   </div>
   </form>
  </ContentBoxPDF>
 </template>


 <style scoped> 
  .img {
    width: 52px;
    height: 52px;
    background: 0% 0% no-repeat padding-box;
    margin-right: 15.56px;
  }

  h2 {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 1.429rem;
    line-height: 1.714rem;
    color: #333333;
    margin-left: 70px;
    margin-top: -50px;
  }

    .description {
     font-family: "Roboto";
     font-weight: normal;
     font-size: 1.143rem;
     line-height: 1.5rem;
     margin-left: 70px;
  }
    button.btn-next {
     background: #ce0a15 0% 0% no-repeat padding-box;
     font: normal bold 13px/15px Roboto;
     letter-spacing: 0px;
     color: #fbfbfb;
     margin-left: 15px;
  }
    button.btn-next {
      width: 168px;
      height: 40px;
      border-radius: 20px;
      text-transform: uppercase;
      text-align: center;
      margin-top: 5px;
      margin-left: 600px;
    }

@media (max-width:768px){
 
 .frame-pdf{
   width: 320px; 
   height: 250px;
   margin-bottom: 100px;
 }   
}
    
    
 





 </style>