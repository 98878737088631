import { render, staticRenderFns } from "./ContentBox3.vue?vue&type=template&id=bd847ac2&scoped=true&"
import script from "./ContentBox3.vue?vue&type=script&lang=ts&"
export * from "./ContentBox3.vue?vue&type=script&lang=ts&"
import style0 from "./ContentBox3.vue?vue&type=style&index=0&id=bd847ac2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd847ac2",
  null
  
)

export default component.exports