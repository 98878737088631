















import Vue from "vue";
import axios from "axios";
import { BAlert } from "bootstrap-vue";
import PostalCodeForm from "../components/PostalCodeForm.vue";

export default Vue.extend({
  name: "Home",
  components: {
    PostalCodeForm,
    BAlert
    
  },
  data() {
    return {
      error: false,
      errors: "",
      loading: false,
      postalCode: this.$route.query.postalcode || null,
      newGroup: false,
      groupList: [],
      dismissSecs: 10,
      dismissCountDown: 0,
      
    };
  },
  methods: {
    async handlePostalCode(postalCode: string) {
      await this.getGroups(postalCode);

      if (this.error) {
        this.errors = 'O CEP digitado não é válido. Favor reveja o CEP e digite novamente!';
        this.error = false 
       } else {
        this.$router.push({
        path: "search",
        query: { postalcode: postalCode },
        });
      }
    },

    async getGroups(postalCode: string) {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/groups/search/`, {
          params: { postcode: postalCode },
        })
        .then((response) => {
          let data = response.data;
          if (data.groupExists) {
            // groups exists for this zipcode
            this.newGroup = true;
            localStorage.groups = JSON.stringify(data.groups);
            localStorage.coordinates = JSON.stringify({
              lat: data.address.latitude,
              lng: data.address.longitude,
            });
          } else {
            // groups not exists for this zipcode
            localStorage.coordinates = JSON.stringify({
              lat: data.address.latitude,
              lng: data.address.longitude,
            });

            localStorage.groups = JSON.stringify(data.groups);
            this.newGroup = false;
          }
          localStorage.newGroup = !data.groupExists;
          localStorage.address = JSON.stringify(data.address);
        })
        .catch(() => {
          this.error = true;
        });
      
      return this.newGroup;
      
    },
    countDownChanged(dismissCountDown: any) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    scroll(){
        window.scrollTo(100, 100);
    },
  },
   watch: {
    error(){
      this.showAlert();
    },
  },
});
