




























































import Vue from 'vue';
import Navbar from '@/components/Navbar.vue';
import SidebarPlugin from 'bootstrap-vue'

export default Vue.extend({
  name: 'Header',
  components: {
    Navbar,
  },
  data() {
    return {
      showSidebar: false,
    };
  },
  methods: {
    handleMenuClick() {
      this.showSidebar = !this.showSidebar;
    },
  },
  
 
});
