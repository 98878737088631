<template>
  <button class="submit-button">{{ label || "Botão" }}</button>
</template>

<script>
export default {
  name: 'SubmitButtonAuth',
  props: {
    label: String,
  },
};
</script>

<style scoped lang="scss">
.submit-button {
  width: 100%;
  min-width: 190px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.063rem;
  font-weight: 700;
  color: white;
  background: $color-primary;
  border-radius: 25px;
  box-shadow: 0px 3px 6px #C1B8B829;;
}

.submit-button:hover {
  background: darken( $color-primary, 10% );
}
</style>
